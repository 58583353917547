import store from 'redux/store'

const isKo = store.getState().theme.locale === 'ko' // if korean lang

export const PolicyTypesEnum = {
  CompanyPolicy: 'CompanyPolicy',
  GroupPolicy: 'GroupPolicy',
  IndividualPolicy: 'IndividualPolicy',
}
export const PolicyTarget = {
  All: 'All',
  Surf: 'Surf',
  Extension: 'Extension',
}
export const PolicyTargetText = {
  Extension: 'Extensions',
  Surf: 'SURF browser',
  All: 'All browsers',
}
export const PolicyTargetColors = {
  Extension: 'purple',
  Surf: 'blue',
  All: 'green',
}
export const RolesEnum = {
  ProductOwner: 'ProductOwner',
  SuperAdmin: 'Admin',
  Admin: 'Editor',
  Contributor: 'Contributor',
  Viewer: 'Viewer',
  TrialUser: 'TrialUser',
}
export const DlpKeysEnum = {
  ScreenCapture: 'ScreenCapture',
  Copy: 'Copy',
  Paste: 'Paste',
  Print: 'Print',
  SaveAs: 'SaveAs',
  Inspect: 'Inspect',
  KeyLogger: 'KeyLogger',
}

export const DaysOptions = isKo
  ? [
      { label: '7 일', value: 7 },
      { label: '10 일', value: 10 },
      { label: '21 일', value: 21 },
      { label: '30 일', value: 30 },
      { label: '60 일', value: 60 },
      { label: '90 일', value: 90 },
    ]
  : [
      { label: '7 days', value: 7 },
      { label: '10 days', value: 10 },
      { label: '21 days', value: 21 },
      { label: '30 days', value: 30 },
      { label: '60 days', value: 60 },
      { label: '90 days', value: 90 },
    ]

export const AlertsTypeEnum = {
  PhishingAttempt: 'Phishing Attempt',
  UnauthorizedDownload: 'Unauthorized Download ',
  UnauthorizedExtension: 'Unauthorized Extension',
  UnauthorizedActions: 'Unauthorized Actions',
}
export const AlertsStatusEnum = {
  New: 'New',
  Ignored: 'Ignore',
  Reviewed: 'Reviewed',
  Resolved: 'Resolved',
}
export const AlertsActionEnum = {
  Resolve: 'Resolve',
  Ignore: 'Ignore',
  Reviewing: 'Reviewing',
}

export const statusEnum = {
  Ignore: 'Ignored',
  New: 'Awaiting',
  Resolved: 'Solved',
  Reviewed: 'Reviewed',
}

export const Constant = {
  True: 'true',
  False: 'false',
  Undefined: 'undefined',
  Null: 'null',
  Email: 'email',
  Password: 'password',
}

export const LoginTypes = {
  CustomLogin: 'CustomLogin',
  Okta: 'Okta',
  OktaWidget: 'OktaWidget',
  ForgeRock: 'ForgeRock',
  GoogleWorkSpace: 'Google WorkSpace',
  AzureAD: 'Azure',
  WindowsAD: 'WindowsAD',
  Google: 'Google',
}
export const AuthSessionTimeUnit = {
  Hour: 'Hour',
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  Unlimited: 'Unlimited',
}
export const AuthenticationType = {
  ACTIVESESSION: 'ACTIVESESSION',
  DiSCONNECT_VALIDATE_SESSION: 'DiSCONNECT_VALIDATE_SESSION',
  MULTIFACTOR_AUTH: 'MULTIFACTOR_AUTH',
  HIBRENATE: 'HIBRENATE',
  TRANSACTIONAL_MFA: 'TRANSACTIONAL_MFA',
}

export const ClientInviteStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  NA: 'N/A',
}

export const AuthConfigType = { DURATION: 'Duration', TIMEUNIT: 'TimeUnit' }
export const WebScrapingConfigType = {
  MAX_DOWNLOAD: 'maxDownloads',
  DURATION: 'duration',
  DURATION_UNIT: 'durationUnit',
}
export const TableSorting = {
  ascend: 'ASC',
  descend: 'DESC',
}
export const BoardingStepsEnum = {
  SignUpCodeConfirmation: 'SignUpCodeConfirmation',
  CompanyInformation: 'CompanyInformation',
  UsagePlanning: 'UsagePlanning',
  CreateWorkspace: 'CreateWorkspace',
  InviteTeammates: 'InviteTeammates',
  Completed: 'Completed',
}

export const UsageTypesEnum = {
  Team: 'Team',
  Personal: 'Personal',
}

export const InviteUserEnum = {
  AllUser: 'AllUsers',
  SelectedUser: 'SelectedUsers',
  SelectedGroup: 'SelectedGroups',
}

export const loginTypeEnum = {
  OktaWidget: 'OktaWidget',
  ForgeRock: 'ForgeRock',
}
export const ActivityTypeEnum = {
  Create: 'Create',
  Update: 'Update',
  Delete: 'Delete',
  SoftDelete: 'Soft Delete',
  CreateMany: 'Create Many',
  UpdateMany: 'Update Many',
  DeleteMany: 'Delete Many',
  SoftDeleteMany: 'Soft Delete Many',
  Email: 'Email',
  ManualCustomEmail: 'ManualCustomEmail',
  Unknown: 'Unknown',
  Login: 'Login',
  AddRelations: 'AddedRelations', // add to existing
  SetRelations: 'SetRelations', // overwrite existing
  RemoveRelations: 'RemoveRelations',
  Call: 'Call',
  Notification: 'Notification',
}

export const TableDefaultColumn = {
  // add for every table
  AlertsVisibleColumns: 'AlertsVisibleColumns',
}

export const EntitiesEnum = {
  Company: 'Company',
  User: 'User',
  Client: 'Client',
  Link: 'Link',
  Group: 'Group',
  PhishingPolicy: 'PhishingPolicy',
  AuthenticationPolicy: 'AuthenticationPolicy',
  DlpPolicy: 'DlpPolicy',
  DownloadPolicy: 'DownloadPolicy',
  ExtensionPolicy: 'ExtensionPolicy',
  WebFilterPolicy: 'Block by categories',
  FileEncryptionRule: 'FileEncryptionRule',
  FileExtensionGroupRule: 'FileExtensionGroupRule',
  CommonSaasRule: 'Frequently used SaaS applications',
  ExceptionDomainRule: 'Exception domains',
  TrustedDomainRule: 'Company domains',
  ChromeStoreRule: 'Allowed extensions',
  ActiveSessionRule: 'Session duration limit',
  BookmarkRule: 'BookmarkRule',
  BookmarkFolder: 'BookmarkFolder',
  Bookmark: 'Bookmark',
  ApprovedFileExtensionRule: 'ApprovedFileExtensionRule',
  DisconnectSessionRule: 'DisconnectSessionRule',
  DlpWebRule: 'DlpWebRule',
  HibernateLogOffRule: 'HibernateLogOffRule',
  UrlClassificationRule: 'Web Category',
  UrlClassification: 'UrlClassification',
  SaasLink: 'SaasLink',
  SaasRuleLinks: 'SaaS Application',
  ScrappingConfiguration: 'ScrappingConfiguration',
  ScrappingRule: 'ScrappingRule',
  SecureStorageRule: 'Remote Storage',
  UploadPreventionRule: 'Upload Prevention',
  DlpControlledLink: 'Dlp Protected Url',
  CompanyConfig: 'CompanyConfig',
  MalwareBlockedLink: 'MalwareBlockedLink',
  MalwareLog: 'MalwareLog',
  AccessRequestNotification: 'AccessRequestNotification',
  AdminAlert: 'AdminAlert',
  CustomFileExtension: 'CustomFileExtension',
  LocalCdn: 'LocalCdn',
  LoginIntegration: 'LoginIntegration',
  MailTemplate: 'MailTemplate',
  MalwareLoggerConfiguration: 'MalwareLoggerConfiguration',
  PhishTank: 'PhishTank',
  AccessibleUrl: 'AccessibleUrl',
  AllowedUrlPolicy: 'Manual filters - Block all',
  BlockedUrlPolicy: 'Manual filters - Allow all',
  MultiFactorAuthRule: 'Enforce MFA',
  TransactionalMfaRule: 'Transactional MFA',
  TransactionalMfaUrl: 'TransactionalMfaUrl',
  PiiRule: 'PiiRule',
  UploadPreventionUrlRule: 'UploadPreventionUrlRule',
  UrlUploadRule: 'UrlUploadRule',
  AllowedBrowserExtension: 'Browser Extension',
  SsoConfiguration: 'SsoConfiguration',
  PersonalLoginRule: 'Personal Login',
  PersonalLoginUrl: 'PersonalLoginUrl',
  JitConfig: 'JitConfig',
  JitDomain: 'JitDomain',
  RegexFiltering: 'RegexFiltering',
  PolicyConfig: 'PolicyConfig',
  ForceLoginRule: 'Force Login',
}

export const AlertsColumnEnum = {
  Status: 'status',
  Group: 'groupId',
  UpdatedAt: 'updatedAt',
  Policy: 'policy',
  RestrictedResource: 'restrictedResource',
  RestrictedResourceName: 'restrictedResourceName',
  Risk: 'risk',
  ClassificationName: 'classificationName',
  InternalIp: 'sourceIp',
  ExternalIp: 'sourceExternalIp',
  DeviceAgent: 'deviceAgent',
  OperatingSystem: 'operatingSystem',
  Client: 'client.login',
}

export const AdminAlertTypeEnum = {
  NewRegistrationAttempt: 'NewRegistrationAttempt ',
}

export const MailTriggersEnum = {
  WORKSPACE_INVITE: 'WORKSPACE_INVITE',
}
export const downloadBehaviorEnum = {
  IgnoreAll: 'IgnoreAll',
  PromptUser: 'PromptUser',
  ScanAll: 'ScanAll',
}
export const DownloadProtectionProviderEnum = {
  VirusTotal: 'VirusTotal',
  MetaDefender: 'MetaDefender',
}
export const SplunkDataOptions = {
  alerts: 'Alerts',
  malwareLogs: 'Malware Logs',
  applications: 'Applications',
  navigation: 'Navigation',
  download: 'Download/Upload',
  changeLogs: 'Change Logs',
}
export const SentinelLogOption = {
  Alerts: 'surf_alerts',
  'Malware Logs': 'surf_malware_logs',
  Applications: 'surf_applications',
  Navigation: 'surf_navigation',
  Download: 'surf_download_upload',
  'Change Logs': 'surf_change_logs',
}
export const ContainerTypeEnum = {
  Windows: 'Windows',
  Mac: 'Mac',
  Linux: 'Linux',
}
export const LogsDataOptions = {
  surfAlert: 'surf-alerts',
  surfLogs: 'surf-logs',
  surfApps: 'surf-applications',
}

export const dpcKeysList = ['isRegistry', 'isFileExist', 'isUploadCertificate', 'isProcessExist']
export const dpcKeysListRule = ['DiscEncryption', 'AntiVirus', 'USBStorage']
export const CompanyFeaturesEnum = {
  useMultipleGroups: 'useAuditor',
  useAdvancedPolicies: 'useAdvancedPolicies',
  useNewAudit: 'useNewAudit',
  useNewSettings: 'useNewSettings',
  policyCacheEnabled: 'policyCacheEnabled',
  allowLambdaFunctions: 'allowLambdaFunctions',
  editUserManagement: 'editUserManagement',
  allowWS: 'allowWS',
  allowAutoAccess: 'allowAutoAccess',
  showMyMessages: 'showMyMessages',
  enableMalwareProtection: 'enableMalwareProtection',
  enableSafeBrowsing: 'enableSafeBrowsing',
  enableLambdaLogin: 'enableLambdaLogin',
}
export const CompanyFeaturesNewUIEnum = {
  useMultipleGroups: 'useAuditor',
  useAdvancedPolicies: 'useAdvancedPolicies',
  // useNewAudit: 'useNewAudit', Obsolete
  // useNewSettings: 'useNewSettings', Obsolete
}
export const PiiDataTypes = {
  ssn: 'Social security numbers',
  creditCard: 'Credit card numbers',
}
export const PoliciesEnum = {
  PhishingPolicy: 'Phishing',
  DLPPolicy: 'DLP',
  DownloadPolicy: 'Download',
  ExtensionPolicy: 'Extension',
  WebFilterPolicy: 'WebFilter',
  AuthenticationPolicy: 'Authentication',
  BookmarkPolicy: 'Bookmark',
  DevicePostureChecksPolicy: 'DPC',
  AdvancedProtectionPolicy: 'AdvancedProtection',
  NavigationCapture: 'NavigationCapture',
  ProxyPolicy: 'Proxy',
  Login: 'Login',
  Logout: 'Logout',
  Disable: 'Disable',
}
export const ReadablePoliciesEnum = {
  PhishingPolicy: 'Phishing',
  DLPPolicy: 'DLP',
  DownloadPolicy: 'Download',
  ExtensionPolicy: 'Extension',
  WebFilterPolicy: 'Web Filter',
  AuthenticationPolicy: 'Authentication',
  BookmarkPolicy: 'Bookmark',
  DevicePostureChecksPolicy: 'Device Posture Check',
  AdvancedProtectionPolicy: 'Advanced Protection',
  NavigationCapture: 'Users Navigation History',
  ProxyPolicy: 'Proxy',
  Login: 'Login',
  Logout: 'Logout',
  Disable: 'Disable',
}
export const PossibleSlackFilterKeysEnum = {
  Policy: 'Policy',
  Classification: 'Classification',
  RestrictedResource: 'RestrictedResource',
}

export const PossibleSlackFilterTypesEnum = {
  IS: 'IS',
  IS_NOT: 'IS_NOT',
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
}

export const PoliciesTypesEnum = {
  Phishing: 'Phishing',
  PreventSignIn: 'PreventSignIn',
  DLP: 'DLP',
  Download: 'Download',
  Upload: 'Upload Prevention',
  Extension: 'Extension',
  WebFilter: 'WebFilter',
  Malware: 'Malware',
}

export const AlertsClassificationEnum = {
  NotAllowedUrl: 'Not Allow Url',
  NotAllowed: 'Not Allowed',
  CreditCard: 'Credit Card',
  UploadPrevention: 'Upload Prevention',
  InsufficientContent: 'Insufficient Content',
  News: 'News',
  Paste: 'Paste',
  Copy: 'Copy',
  BlockedUrl: 'Blocked Url',
  Uncategorized: 'Uncategorized',
  Government: 'Government',
}

export const ActiveTab = {
  Users: 'Users',
  Groups: 'Groups',
  Apps: 'Apps',
  Admins: 'Admins',
  SessionRecording: 'SessionRecording',
  UserManagementTabs: 'user-management-tab',
  ChangeLogsTab: 'change-logs-tab',
  MalwareLogsTab: 'malware-logs-tab',
  PerformanceLogsTab: 'performance-logs-tab',
  NavigationHistoryTab: 'navigation-history-tab',
  changeLogs: {
    ByUser: 'By user',
    ByApp: 'By app',
    ByGroup: 'By group',
    Session: 'Session recording',
  },
  Policies: {
    AccessControl: 'Access Control',
    ContentControl: 'Content Control',
    DataSecurity: 'Data Security',
    BrowserCustomization: 'Browser Customization',
  },
}

export const UrlGroupEnum = {
  ParentalControl: 'Parental Control',
  Security: 'Security',
  Productivity: 'Productivity',
  GeneralUse: 'General Use',
}

export const DpcOsType = {
  Windows: 'WINDOWS',
  Mac: 'MAC',
}

export const DpcRegKeyLabel = {
  WINDOWS: 'Key',
  MAC: 'Full plist path, including key',
}

export const ThresholdKeys = {
  UserAlerts: 'UserAlerts',
  UserBlocks: 'UserBlocks',
  CpuUsage: 'CpuUsage',
  NetworkUsage: 'NetworkUsage',
  PingResponse: 'PingResponse',
}

export const SecurityPostureEnum = {
  Antivirus: 'Antivirus',
  USBStorage: 'USBStorage',
  DiskEncryption: 'Disk Encryption',
  RegistryKeys: 'Registry Keys',
  FileExist: 'File Exist',
  Certificate: 'Certificate',
}

export const RiskEnum = {
  HighRisk: 'High risk',
  ModerateRisk: 'Moderate risk',
  LowRisk: 'Low risk',
  Undefined: 'Undefined',
}

export const UserRisk = {
  0: { color: 'green', status: 'Regular' },
  1: { color: 'orange', status: 'Moderate' },
  2: { color: 'red', status: 'High' },
}

export const SelectionEnum = {
  Users: 'Users',
  Locations: 'Locations',
  Networks: 'Networks',
  SecurityPosture: 'Security posture',
  Risk: 'Risk',
}
export const LoginOriginLogo = {
  Firefox: '/img/origin_mozilla.svg',
  Chrome: '/img/origin_chrome.svg',
  Safari: '/img/origin_safari.svg',
  Edge: '/img/origin_edge.svg',
  browser: '/img/origin_surf.svg',
}

export const OriginKinds = {
  Firefox: 'Firefox',
  Chrome: 'Chrome',
  Safari: 'Safari',
  browser: 'browser',
}

export const NetworkEnum = {
  US: 'Offices US',
  Canada: 'Offices Canada',
  Israel: 'Offices Israel',
  Cyprus: 'Offices Cyprus',
  England: 'Offices England',
}

export const countryGroupEnum = {
  EMEA: 'EMEA',
  USAndCanada: 'US And Canada',
  LATAM: 'LATAM',
  EasternEurope: 'Eastern Europe',
  SanctionedCountries: 'Sanctioned countries',
}

export const countryListEnum = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas (the)',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CD: 'Congo (the Democratic Republic of the)',
  CG: 'Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands [Malvinas]',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (the Democratic People's Republic of)",
  KR: 'Korea (the Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (the Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  UM: 'United States Minor Outlying Islands',
  US: 'United States of America',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands',
}

export const majorCitiesEnum = {
  London: 'London, UK',
  Paris: 'Paris, France',
  NewYork: 'New York, USA',
  Tokyo: 'Tokyo, Japan',
  Dubai: 'Dubai, UAE',
  Barcelona: 'Barcelona, Spain',
  Rome: 'Rome, Italy',
  Madrid: 'Madrid, Spain',
  Amsterdam: 'Amsterdam, Netherlands',
  Prague: 'Prague, Czech',
  LosAngeles: 'Los Angeles, USA',
  Chicago: 'Chicago, USA',
  SanFrancisco: 'San Francisco, USA',
  Berlin: 'Berlin, Germany',
  HongKong: 'Hong Kong, China',
  Washington: 'Washington, USA',
  Beijing: 'Beijing, China',
  Dublin: 'Dublin, Ireland',
  Istanbul: 'Istanbul, Türkiye',
  LasVegas: 'Las Vegas, USA',
  Milan: 'Milan, Italy',
  Budapest: 'Budapest, Hungar',
  Toronto: 'Toronto, Canada',
  Sydney: 'Sydney, Australia',
  Seoul: 'Seoul, South Korea',
  Doha: 'Doha, Qatar',
  AbuDhabi: 'Abu Dhabi, UAE',
  Osaka: 'Osaka, Japan',
  Bangkok: 'Bangkok, Thailand',
  Vienna: 'Vienna, Austria',
  SanDiego: 'San Diego, USA',
  SãoPaulo: 'São Paulo, Brazil',
  Melbourne: 'Melbourne, Australia',
  Zurich: 'Zurich, Switzerland',
  Boston: 'Boston, USA',
  Lisbon: 'Lisbon, Portugal',
  Warsaw: 'Warsaw, Poland',
  Seattle: 'Seattle, USA',
  Orlando: 'Orlando, USA',
  Munich: 'Munich, Germany',
  Houston: 'Houston, USA',
  Austin: 'Austin, USA',
  BuenosAires: 'Buenos Aires, Argentina',
  Naples: 'Naples, Italy',
  Copenhagen: 'Copenhagen, Denmark',
  Dallas: 'Dallas, USA',
  Helsinki: 'Helsinki, Finland',
  Frankfurt: 'Frankfurt, Germany',
  Atlanta: 'Atlanta, USA',
  Stockholm: 'Stockholm, Sweden',
  Miami: 'Miami, USA',
  Athens: 'Athens, Greece',
  RiodeJaneiro: 'Rio de Janeiro, Brazil',
  Hamburg: 'Hamburg, Germany',
  Denver: 'Denver, USA',
  Montreal: 'Montreal, Canada',
  Brussels: 'Brussels, Belgium',
  TelAviv: 'Tel Aviv, Israel',
  Oslo: 'Oslo, Norway',
  Taipei: 'Taipei, Taiwan',
  Valencia: 'Valencia, Spain',
  Minneapolis: 'Minneapolis, USA',
  Philadelphia: 'Philadelphia, USA',
  Calgary: 'Calgary, Canada',
  Portland: 'Portland, USA',
  Nashville: 'Nashville, USA',
  Auckland: 'Auckland, New Zealand',
  Vancouver: 'Vancouver, Canada',
  Santiago: 'Santiago, Chile',
  MexicoCity: 'Mexico City, Mexico',
  Mumbai: 'Mumbai, India',
  Shanghai: 'Shanghai, China',
  SanJose: 'San Jose, USA',
  Lyon: 'Lyon, France',
  Bilbao: 'Bilbao, Spain',
  Liverpool: 'Liverpool, UK',
  NewOrleans: 'New Orleans, USA',
  Brisbane: 'Brisbane, Australia',
  Manchester: 'Manchester, UK',
  Fukuoka: 'Fukuoka, Japan',
  Seville: 'Seville, Spain',
  Riyadh: 'Riyadh, Saudi Arabia',
  Jerusalem: 'Jerusalem, Israel',
  Nanjing: 'Nanjing, China',
  Minsk: 'Minsk, Belarus',
  SaltLake: 'Salt Lake City, USA',
  Phoenix: 'Phoenix, USA',
  Jakarta: 'Jakarta, Indonesia',
  Gothenburg: 'Gothenburg, Sweden',
  Perth: 'Perth, Australia',
  Glasgow: 'Glasgow, UK',
  Nagoya: 'Nagoya, Japan',
  Baltimore: 'Baltimore, USA',
  Stuttgart: 'Stuttgart, Germany',
  Ottawa: 'Ottawa, Canada',
  Hanoi: 'Hanoi, Vietnam',
  Sendai: 'Sendai, Japan',
  Cologne: 'Cologne, Germany',
  Marseille: 'Marseille, France',
}

export const SamlHttpMethodsEnum = {
  HTTPPOST: 'HTTP-POST',
  HTTPREDIRECT: 'HTTP-Redirect',
}
export const IntegrationNamesEnum = {
  BOUNDARY: 'Boundary',
  AZURE: 'Azure',
  ZOHOWORKDRIVE: 'ZohoWorkDrive',
  VAULT: 'Vault',
  SPLUNK: 'Splunk',
  ELASTICSEARCH: 'ElasticSearch',
  DATADOG: 'DataDog',
  SAML2: 'SAML2',
  Slack: 'Slack',
  CORALOGIX: 'Coralogix',
  Guacamole: 'Guacamole',
  MICROSOFT_INFO_PROTECTION: 'MIP',
  MICROSOFT_SHAREPOINT: 'SharePoint',
  LOGZIO: 'logz.io',
  SHIFT_MANAGEMENT: 'ShiftManagement',
  MICROSOFTSENTINEL: 'MicrosoftSentinel',
}
export const SlackChannelTypeEnum = {
  ActivityLogger: 'ActivityLogger',
  Alerts: 'Alerts',
}
export const BoundaryModalStateEnum = {
  LIST: 'LIST',
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  DUPLICATE: 'DUPLICATE',
}
export const BoundaryUserAuthTypes = {
  Certificate: 'Certificate',
  Password: 'Password',
}
export const policyRuleOptionKeyValue = {
  details: 'policy-details',
  scope: 'scope',
  target: 'target',
  action: 'action',
  controls: 'controls',
  exceptions: 'exceptions',
}
// Group of controls keys are the same as the payload keys
export const policiesKeys = {
  copy: 'Copy',
  paste: 'Paste',
  copyPasteContent: 'copyPasteContent',
  copyPasteBetween: 'copyPasteBetween',
  print: 'Print',
  viewSourceCode: 'ViewSourceCode',
  viewMaskedData: 'requestAccess',
  devTool: 'DevTools',
  downloads: 'downloads',
  downloadFileEncryption: 'downloadFileEncryption',
  webScrapping: 'webScrapping',
  fileDownloadAlert: 'fileDownloadAlert',
  watermark: 'Watermark',
  uploads: 'uploads',
  uploadFileSize: 'uploadFileSize',
  screenCaptureSharing: 'ScreenCapture',
  readOnlyWebsite: 'readOnlyWebsite',
  accessRequest: 'accessRequest',
  downloadRequestAccess: 'downloadRequestAccess',
  categoriesOfContent: 'categoriesOfContent',
  wcfAccessRequest: 'wcfAccessRequest',
  keywordsNRegex: 'keywordsNRegex',
  sqlInjection: 'sqlInjection',
  domainVerification: 'domainVerification',
  piiDataOnSpreadsheets: 'excelProtection',
  safeBrowsing: 'safeBrowsing',
  creditCard: 'isSnnCreditCard',
  socialSecurity: 'socialSecurity',
  chatgptDataAnonymization: 'chatgptDataAnonymization',
  customData: 'allowCustom',
  chatgptCharacterLimitation: 'chatgptCharacterLimitation',
  chatgptPastingPii: 'restrictive',
  phishingCompanyDomain: 'phishingCompanyDomain',
  phishingSaasApp: 'phishingSaasApp',
  phishingBusinessCredential: 'phishingBusinessCredential',
  phishingCustomRules: 'phishingCustomRules',
  advancedProtection: 'advancedProtection',
  weakpasswordAlert: 'weakpasswordAlert',
  admxRules: 'admxRules',
  iFrameContentScript: 'iFrameContentScript',
  maliciousProcessDetection: 'maliciousProcessDetection',
  contentScriptExclusionManager: 'contentScriptExclusionManager',
  jitAccessManagement: 'jitAccessManagement',
  dataLossPrevention: 'dataLossPrevention',
  emailSendingControl: 'emailSendingControl',
  pdfSecurityPass: 'pdfSecurityPass',
  bookmarkManagement: 'bookmarkManagement',
  authenticationControls: 'authenticationControls',
  multifactorAuthentication: 'multifactorAuthentication',
  loginEnforcement: 'loginEnforcement',
  transactionalMfa: 'transactionalMfa',
  sessionDurationLimit: 'sessionDurationLimit',
  autoHibernateLogoutEnforcement: 'autoHibernateLogoutEnforcement',
  roboticProcessAutomation: 'roboticProcessAutomation',
  personalCredential: 'personalCredential',
  keyLogging: 'keyLogging',
  browserMonitoring: 'browserMonitoring',
  monitoredApplication: 'monitoredApplication',
  captureSiteActive: 'captureSiteActive',
  DLP: 'Data Loss Prevention',
  WCF: 'Web Content Filtering',
  PII: 'Personally Identifiable Information',
  Phishing: 'Phishing Protection',
  ALL: 'All policies',
  AUTH: 'Authentication Controls',
  BOOKMARKS: 'Bookmarks',
  DOWNLOAD: 'Download',
  approvedFileExtensionsRule: 'approvedFileExtensionsRule',
  webAccessRestriction: 'webAccessRestriction',
  iFrameMonitor: 'iFrameMonitor',
  proxyPolicy: 'proxyPolicy',
  keyLoggingProtection: 'keyLoggingProtection',
  downloadManagement: 'downloadManagement',
  downloadMalwareScan: 'downloadMalwareScan',
  corporateDownloadEnforce: 'corporateDownloadEnforce',
}

export const policyRulesString = {
  piiProtectedActions: 'piiProtectedActions',
  protectedActions: 'protectedActions',
  rules: 'rules',
  DevTools: 'DevTools',
  KeyLogger: 'KeyLogger',
  ScreenCapture: 'ScreenCapture',
  ScreenShare: 'ScreenShare',
  isEnabled: 'isEnabled',
}

export const nonExtandableCard = [
  policiesKeys.phishingBusinessCredential,
  policiesKeys.phishingSaasApp,
  policiesKeys.phishingCompanyDomain,
  policiesKeys.weakpasswordAlert,
  policiesKeys.advancedProtection,
  policiesKeys.jitAccessManagement,
  policiesKeys.iFrameContentScript,
  policiesKeys.contentScriptExclusionManager,
  policiesKeys.maliciousProcessDetection,
  policiesKeys.monitoredApplication,
  policiesKeys.loginEnforcement,
  policiesKeys.accessRequest,
  policiesKeys.wcfAccessRequest,
  policiesKeys.downloadRequestAccess,
  policiesKeys.devTool,
  policiesKeys.readOnlyWebsite,
  policiesKeys.corporateDownloadEnforce,
  policiesKeys.iFrameMonitor,
  policiesKeys.keyLoggingProtection,
  policiesKeys.personalCredential,
  policiesKeys.emailSendingControl,
  policiesKeys.pdfSecurityPass,
  policiesKeys.safeBrowsing,
  policiesKeys.corporateDownloadEnforce,
]

export const clearPolicyRuleSessionStorage = () => {
  sessionStorage.removeItem(policyRuleOptionKeyValue.action)
  sessionStorage.removeItem(policyRuleOptionKeyValue.controls)
  sessionStorage.removeItem(policyRuleOptionKeyValue.details)
  sessionStorage.removeItem(policyRuleOptionKeyValue.exceptions)
  sessionStorage.removeItem(policyRuleOptionKeyValue.scope)
  sessionStorage.removeItem(policyRuleOptionKeyValue.target)
}

export const keysLargeControlCard = [
  policiesKeys.downloads,
  policiesKeys.uploads,
  policiesKeys.categoriesOfContent,
  policiesKeys.chatgptDataAnonymization,
  policiesKeys.customData,
  policiesKeys.admxRules,
  policiesKeys.screenCaptureSharing,
  policiesKeys.authenticationControls,
  policiesKeys.downloadManagement,
  policiesKeys.fileDownloadAlert,
  policiesKeys.bookmarkManagement,
  policiesKeys.keywordsNRegex,
  policiesKeys.downloadMalwareScan,
  policiesKeys.roboticProcessAutomation,
]

export function convertToSunburstChart(array = []) {
  const result = {
    label: 'Policies',
    children: [],
    uv: 0,
    sum: 0,
    count: 0,
    name: 'Policies',
  }

  const groupedByPolicy = groupBy(array, 'policy')

  for (const policy in groupedByPolicy) {
    const policyObj = {
      label: policy,
      children: [],
      uv: 0,
      sum: 0,
      count: 0,
      name: policy,
    }

    const currentArray = groupedByPolicy[policy]
    const groupedByClassification = groupBy(currentArray, 'classificationName')

    for (const classification in groupedByClassification) {
      const classificationObj = {
        label: classification,
        children: [],
        uv: 0,
        sum: 0,
        count: 0,
        name: classification,
      }
      const classificationArray = groupedByClassification[classification]
      if (classificationArray?.some((obj) => obj.restrictedResourceName !== null)) {
        const groupedByResourceName = groupBy(classificationArray, 'restrictedResourceName')
        for (const resourceName in groupedByResourceName) {
          const resourceObj = {
            label: resourceName,
            children: [],
            uv: 0,
            sum: 0,
            count: 0,
            name: resourceName || 'No Resource Name',
          }
          const resourceArray = groupedByResourceName[resourceName]
          const groupedByRestrictedResource = groupBy(resourceArray, 'restrictedResource')
          for (const resource in groupedByRestrictedResource) {
            const subResourceObj = {
              label: resource,
              children: null,
              uv: 0,
              sum: 0,
              count: 0,
              name: resource,
            }
            const subResourceArray = groupedByRestrictedResource[resource]
            subResourceObj.uv = subResourceArray?.length
            subResourceObj.sum = subResourceArray?.length

            resourceObj.children?.push(subResourceObj)
            resourceObj.uv += subResourceObj?.uv
            resourceObj.sum += subResourceObj?.sum
            resourceObj.count++
          }

          classificationObj.children.push(resourceObj)
          classificationObj.uv += resourceObj.uv
          classificationObj.sum += resourceObj.sum
          classificationObj.count++
        }
      } else {
        const groupedByRestrictedResource = groupBy(classificationArray, 'restrictedResource')

        for (const resource in groupedByRestrictedResource) {
          const resourceObj = {
            label: resource,
            children: null,
            uv: 0,
            sum: 0,
            count: 0,
            name: resource,
          }

          const resourceArray = groupedByRestrictedResource[resource]
          resourceObj.uv = resourceArray.length
          resourceObj.sum = resourceArray.length

          classificationObj.children.push(resourceObj)
          classificationObj.uv += resourceObj.uv
          classificationObj.sum += resourceObj.sum
          classificationObj.count++
        }
      }

      policyObj.children.push(classificationObj)
      policyObj.uv += classificationObj.uv
      policyObj.sum += classificationObj.sum
      policyObj.count++
    }

    result.children.push(policyObj)
    result.uv += policyObj.uv
    result.sum += policyObj.sum
    result.count++
  }

  return result
}

// Helper function to group array of objects by a specified key
function groupBy(array, key) {
  return array?.reduce((result, item) => {
    ;(result[item[key]] = result[item[key]] || []).push(item)
    return result
  }, {})
}

export function groupDataByDate(data, daysBack) {
  const today = new Date()
  const highestDate = new Date(Math.max(...data.map((item) => new Date(item.date))))
  const dateSevenDaysAgo = new Date(today)
  dateSevenDaysAgo.setDate(today.getDate() - daysBack)
  const result = []
  const groupedDataMap = new Map()
  data?.forEach((item) => {
    const { date, policy, count } = item
    const itemDate = new Date(date)
    if (itemDate <= highestDate && itemDate >= dateSevenDaysAgo) {
      const formattedDate = itemDate.toISOString().split('T')[0]
      const existingGroup = groupedDataMap.get(formattedDate) || { date: formattedDate, data: {} }
      existingGroup.data[policy] = (existingGroup.data[policy] || 0) + parseInt(count)
      groupedDataMap.set(formattedDate, existingGroup)
    }
  })
  result.push(...groupedDataMap.values())
  return result
}
// export function groupStatusByDate(data, daysBack) {
export function groupStatusByDate(data) {
  const result = []
  const groupedDataMap = new Map()
  data?.forEach((item) => {
    const { updatedAt, status } = item
    const itemDate = new Date(updatedAt)
    const formattedDate = itemDate?.toISOString()?.split('T')[0]
    const existingGroup = groupedDataMap?.get(formattedDate) || {
      date: formattedDate,
      data: { New: 0, Resolved: 0 },
    }
    if (status === 'New') {
      existingGroup.data.New += 1
    } else {
      existingGroup.data.Resolved += 1
    }
    groupedDataMap.set(formattedDate, existingGroup)
  })
  result.push(...groupedDataMap.values())
  return result.reverse()
}

export const CompanyTypeEnum = {
  Regular: 'Regular',
  Trial: 'Trial',
}
export const AzureLoginOptionsEnum = {
  SAML: 'SAML',
  OIDC: 'OIDC',
}
export const ShortcutPreferences = {
  mostVisited: 'mostVisited',
  companyApps: 'companyApps',
  myShortCut: 'myShortCut',
}

export function formatTimeIntervals(intervals) {
  if (intervals) {
    return intervals?.map((interval) => {
      const toDayTime = interval?.to.split(':')
      const fromDayTime = interval?.from.split(':')
      const toDay = toDayTime[0]?.charAt(0).toUpperCase() + toDayTime[0]?.slice(1)
      const fromDay = fromDayTime[0]?.charAt(0).toUpperCase() + fromDayTime[0]?.slice(1)
      const to = `${toDay} ${toDayTime[1]}:${toDayTime[2]}`
      const from = `${fromDay} ${fromDayTime[1]}:${fromDayTime[2]}`
      return `${from} - ${to}`
    })
  }
  return []
}

export function formatTimeIntervalsForAPI(intervals) {
  return intervals.map((interval) => {
    // Split the string by ' - ' to separate the 'to' and 'from' parts
    const [toPart, fromPart] = interval.split(' - ')

    // Extract day and time from 'to' and 'from' parts
    const [toDay, toTime] = toPart.split(' ')
    const [fromDay, fromTime] = fromPart.split(' ')

    // Convert day names to lowercase
    const toDayLower = toDay.toLowerCase()
    const fromDayLower = fromDay.toLowerCase()

    // Construct the 'to' and 'from' fields in the original format
    const to = `${toDayLower}:${toTime}`
    const from = `${fromDayLower}:${fromTime}`

    // Return the object with 'to' and 'from' fields
    return { to, from }
  })
}
